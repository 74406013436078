import request from '@/utils/request'

//工序定义列表
export function listProcess(params) {
  return request({
    url: '/api/system/mes/process/listProcess',
    method: 'get',
    params
  })
}

//添加工序定义
export function addProcess(data) {
  return request({
    url: '/api/system/mes/process/addProcess',
    method: 'post',
    data
  })
}

//工序定义信息
export function getProcessByProcessId(processId) {
  return request({
    url: '/api/system/mes/process/getProcessByProcessId?processId=' + processId,
    method: 'get'
  })
}

//修改工序定义
export function modifyProcess(data) {
  return request({
    url: '/api/system/mes/process/modifyProcess',
    method: 'post',
    data
  })
}

//删除工序定义
export function delProcess(data) {
  return request({
    url: '/api/system/mes/process/delProcess',
    method: 'post',
    data
  })
}