import request from '@/utils/request'

//工艺路线列表
export function listCraftLine(params) {
  return request({
    url: '/api/system/mes/process/craft/line/listCraftLine',
    method: 'get',
    params
  })
}

//添加工艺路线
export function addCraftLine(data) {
  return request({
    url: '/api/system/mes/process/craft/line/addCraftLine',
    method: 'post',
    data
  })
}

//工艺路线信息
export function getCraftLineByCraftLineId(craftLineId) {
  return request({
    url: '/api/system/mes/process/craft/line/getCraftLineByCraftLineId?craftLineId=' + craftLineId,
    method: 'get'
  })
}

//修改工艺路线
export function modifyCraftLine(data) {
  return request({
    url: '/api/system/mes/process/craft/line/modifyCraftLine',
    method: 'post',
    data
  })
}

//删除工艺路线
export function delCraftLine(data) {
  return request({
    url: '/api/system/mes/process/craft/line/delCraftLine',
    method: 'post',
    data
  })
}

//线路工序列表
export function listCraftLineProcess(params) {
  return request({
    url: '/api/system/mes/process/craft/line/listCraftLineProcess',
    method: 'get',
    params
  })
}

//添加线路工序
export function addCraftLineProcess(data) {
  return request({
    url: '/api/system/mes/process/craft/line/addCraftLineProcess',
    method: 'post',
    data
  })
}

//删除线路工序
export function delCraftLineProcess(data) {
  return request({
    url: '/api/system/mes/process/craft/line/delCraftLineProcess',
    method: 'post',
    data
  })
}

//交换线路工序位置
export function exchangeCraftLineProcessPosition(data) {
  return request({
    url: '/api/system/mes/process/craft/line/exchangeCraftLineProcessPosition',
    method: 'post',
    data
  })
}