import request from '@/utils/request'

//工序人员列表
export function listProcessMan(params) {
  return request({
    url: '/api/system/mes/processman/listProcessMan',
    method: 'get',
    params
  })
}

//添加工序人员
export function addProcessManReturnResp(data) {
  return request({
    url: '/api/system/mes/processman/addProcessManReturnResp',
    method: 'post',
    data
  })
}

//工序人员信息
export function getProcessManByProcessManId(processManId) {
  return request({
    url: '/api/system/mes/processman/getProcessManByProcessManId?processManId=' + processManId,
    method: 'get'
  })
}

//修改工序人员
export function modifyProcessMan(data) {
  return request({
    url: '/api/system/mes/processman/modifyProcessMan',
    method: 'post',
    data
  })
}

//删除工序人员
export function delProcessMan(data) {
  return request({
    url: '/api/system/mes/processman/delProcessMan',
    method: 'post',
    data
  })
}